{
  "name": "@vercel/speed-insights",
  "version": "1.0.12",
  "description": "Speed Insights is a tool for measuring web performance and providing suggestions for improvement.",
  "keywords": [
    "speed-insights",
    "vercel"
  ],
  "repository": {
    "url": "github:vercel/speed-insights",
    "directory": "packages/web"
  },
  "license": "Apache-2.0",
  "exports": {
    "./package.json": "./package.json",
    ".": {
      "browser": "./dist/index.mjs",
      "import": "./dist/index.mjs",
      "require": "./dist/index.js"
    },
    "./astro": {
      "import": "./dist/astro/component.ts"
    },
    "./next": {
      "browser": "./dist/next/index.mjs",
      "import": "./dist/next/index.mjs",
      "require": "./dist/next/index.js"
    },
    "./nuxt": {
      "browser": "./dist/nuxt/index.mjs",
      "import": "./dist/nuxt/index.mjs",
      "require": "./dist/nuxt/index.js"
    },
    "./react": {
      "browser": "./dist/react/index.mjs",
      "import": "./dist/react/index.mjs",
      "require": "./dist/react/index.js"
    },
    "./remix": {
      "browser": "./dist/remix/index.mjs",
      "import": "./dist/remix/index.mjs",
      "require": "./dist/remix/index.js"
    },
    "./sveltekit": {
      "svelte": "./dist/sveltekit/index.mjs",
      "types": "./dist/sveltekit/index.d.ts"
    },
    "./vue": {
      "browser": "./dist/vue/index.mjs",
      "import": "./dist/vue/index.mjs",
      "require": "./dist/vue/index.js"
    }
  },
  "main": "./dist/index.js",
  "types": "./dist/index.d.ts",
  "typesVersions": {
    "*": {
      "*": [
        "dist/index.d.ts"
      ],
      "react": [
        "dist/react/index.d.ts"
      ],
      "next": [
        "dist/next/index.d.ts"
      ],
      "nuxt": [
        "dist/nuxt/index.d.ts"
      ],
      "remix": [
        "dist/remix/index.d.ts"
      ],
      "sveltekit": [
        "dist/sveltekit/index.d.ts"
      ],
      "vue": [
        "dist/vue/index.d.ts"
      ]
    }
  },
  "scripts": {
    "build": "tsup && pnpm copy-astro",
    "copy-astro": "cp -R src/astro dist/",
    "dev": "pnpm copy-astro && tsup --watch",
    "postinstall": "node scripts/postinstall.mjs",
    "lint": "eslint .",
    "lint-fix": "eslint . --fix",
    "test": "jest",
    "type-check": "tsc --noEmit"
  },
  "devDependencies": {
    "@remix-run/react": "^2.5.0",
    "@sveltejs/kit": "^1.20.4",
    "@swc/core": "^1.3.103",
    "@swc/jest": "^0.2.29",
    "@testing-library/jest-dom": "^6.2.0",
    "@testing-library/react": "^14.1.2",
    "@types/jest": "^29.5.11",
    "@types/node": "^20.11.4",
    "@types/react": "^18.2.48",
    "copyfiles": "^2.4.1",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "next": "^14.0.4",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "svelte": "^4.2.8",
    "tsup": "7.2.0",
    "vue": "^3.4.14",
    "vue-router": "^4.2.5"
  },
  "peerDependencies": {
    "@sveltejs/kit": "^1 || ^2",
    "next": ">= 13",
    "react": "^18 || ^19",
    "svelte": "^4",
    "vue": "^3",
    "vue-router": "^4"
  },
  "peerDependenciesMeta": {
    "@sveltejs/kit": {
      "optional": true
    },
    "next": {
      "optional": true
    },
    "react": {
      "optional": true
    },
    "svelte": {
      "optional": true
    },
    "vue": {
      "optional": true
    },
    "vue-router": {
      "optional": true
    }
  }
}
